<template>
  <div class="login">
    <!-- 电脑端 -->
    <div class="pc">
      <!-- 头部区域 -->
      <div class="head">
        <!-- 左边部分 -->
        <div class="head-left">
          <div class="up">
            <div class="up-box"></div>
          </div>
          <div class="down">
            <div class="down-box"></div>
          </div>
        </div>
        <!-- 左边部分 END -->

        <!-- 中间部分 -->
        <div class="head-mid">
          <div class="mid">
            <div class="roll">
              <div class="rollbox"></div>
              <div class="rollbox1"></div>
            </div>
          </div>
        </div>
        <!-- 中间部分 END -->

        <!-- 右边登录部分 -->
        <div class="head-right">
          <div class="r-box">
            <div class="r-boxbg">
              <div style="margin-top: 10px">ssh login</div>
              <div class="title">
                Administrator@MYCOM
                <span>MINGW64</span>
                <i>~</i>
                <br />$ ssh root@192.168.1.5 <br />bash: shh: command not found
              </div>
              <div style="height: 10px"></div>
              <div class="title">
                Administrator@MYCOM
                <span>MINGW64</span>
                <i>~</i>
                <br />$ ssh root@192.168.1.5 <br />root@192.168.1.5's username:
                <span>
                  <input
                    v-model="state.Name"
                    type="text"
                    size="15"
                    style="height=15px;"
                  />
                </span>
                <div>
                  root@192.168.1.5's password:
                  <input
                    v-model="state.Pwd"
                    size="15"
                    style="height=15px;"
                    type="password"
                  />
                </div>
                <span class="login" @click="login">Click me login</span>
                <div class="back" @click="goback">back</div>
                <div class="message">{{ state.LoginMessage }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边登录部分 END-->
      </div>
      <!-- 头部区域 END-->
    </div>
    <!-- 电脑端 -->
  </div>
</template>

<script>
import { defineComponent, setup, ref, reactive } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Login",
  setup() {
    const state = reactive({
      Name: "",
      Pwd: "",
      LoginMessage: "欢迎大神，请登录！",
    });
    const router = useRouter();
    const login = () => {
      if (state.Name == "") {
        state.LoginMessage = "注意：请输入账号！";
        return;
      }
      if (state.Pwd == "") {
        state.LoginMessage = "注意：请输入密码！";
        return;
      }
      if (state.Name == "xiaoyouzi" && state.Pwd == "1314") {
        state.LoginMessage = "注意：登录成功！";
      }
      state.LoginMessage = "注意：登录失败，登录账号或秘密失败！";
    };
    const goback = () => {
      router.push("/");
    };

    return {
      state,
      login,
      goback,
    };
  },
});
</script>

<style lang="less" scoped>
@keyframes myroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-900px);
  }
}
@keyframes myrolll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-900px);
  }
}
.login {
  .pc {
    width: 100vw;
    height: 100vh;
    background: url("../assets/img/login-bgc.jpg") no-repeat 0 0 transparent;
    background-size: cover;
    .head {
      display: flex;
      flex-flow: row nowrap;
      padding: 5vh 0 0 0;
      box-sizing: border-box;

      //<!-- 左边部分 -->
      .head-left {
        width: 23%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        .up {
          width: 80%;
          height: 50%;
          .up-box {
            width: 100%;
            height: 90%;
            background-image: url("../assets/img/hackhead.gif");
            background-position: center 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 10px;
            box-shadow: 3px 3px 6px 3px #000; //#0e48bb
            margin-top: 6.5%;
            border: 2px solid #0e48bb;
          }
        }
        .down {
          width: 80%;
          height: 50%;
          .down-box {
            width: 100%;
            height: 90%;
            background-image: url("../assets/img/hackmap.gif");
            background-position: center 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 10px;
            box-shadow: 3px 3px 6px 3px #000;
            margin-top: 3%;
            border: 2px solid rgba(255, 255, 255, 0.6);
          }
        }
      }
      //<!-- 左边部分 END-->

      // <!-- 中间部分 -->
      .head-mid {
        height: 70vh;
        width: 45%;
        .mid {
          height: 100%;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 10px;
          border: 2px solid #0e48bb;
          box-shadow: 3px 3px 6px 3px #000;
          position: relative;
          overflow: hidden;
          .roll {
            width: 100%;
            height: 900px;
            position: absolute;
            animation: myroll 7s linear 0s infinite;
            .rollbox {
              // -webkit-animation: myroll 7s linear 0s infinite;
              height: 900px;
              width: 100%;
              background-image: url("../assets/img/code.png");
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .rollbox1 {
              // animation: myrolll 7s linear 7s infinite;
              // -webkit-animation: myroll 7s linear 7s infinite;
              height: 900px;
              width: 100%;
              background-image: url("../assets/img/code.png");
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
      // <!-- 中间部分 END-->

      // <!-- 右边登录部分 -->
      .head-right {
        width: 32%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        .r-box {
          height: 80%;
          width: 80%;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 10px;
          border: 2px solid #0e48bb;
          box-shadow: 3px 3px 6px 3px #000;
          transform: skewY(5deg) translateZ(30px);
          .r-boxbg {
            position: relative;
            height: 90%;
            width: 90%;
            border: 1px solid #fff;
            margin: auto;
            margin-top: 1.25rem;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 16px;
            padding: 0 5px;
            box-sizing: border-box;
            .title {
              text-align: left;
              font-size: 16px;
              color: #82e952;
              margin-top: 10px;
              padding-left: 3px;
              span {
                color: pink;
              }
              i {
                color: yellow;
              }
            }
            .login {
              cursor: pointer;
            }
            .back {
              cursor: pointer;
            }
            input {
              background: none;
              outline: none;
              border: none;
              color: #82e952;
              height: 14px;
              font-size: 14px;
            }
            .message {
              position: absolute;
              color: yellow;
              left: 3px;
              bottom: 5px;
            }
          }
        }
      }
      //<!-- 右边登录部分 END-->
    }
  }
}
@media (max-width: 420px) {
  .login .pc .head {
    flex-flow: column;
    padding: 0;
  }
  .login .pc .head .head-left {
    display: none;
  }
  .login .pc .head .head-mid {
    width: 100%;
    height: 100vh;
  }
  .login .pc .head .head-right {
    width: 100%;
    height: 450px;
    position: absolute;
    top: 10vh;
  }
  .login .pc .head .head-right .r-box{
    height: 100%;
    width: 80%;
    transform: none;
  }
}
</style>